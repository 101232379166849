import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5057aeb6 = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a1ccc972 = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _786c6439 = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _b6723d84 = () => interopDefault(import('../uicore/pages/base/service/list/_parentId.vue' /* webpackChunkName: "pages/base/service/list/_parentId" */))
const _89d1f496 = () => interopDefault(import('../uicore/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "pages/base/service/info/_tsrId" */))
const _02671eef = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _0ec6972c = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _2b3cb2bc = () => interopDefault(import('../uicore/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "pages/base/application/monitor/_appId" */))
const _8abf1906 = () => interopDefault(import('../uicore/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "pages/arm/service/list/_parentId" */))
const _7cb49854 = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _7e6f2ee6 = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _789a0161 = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _17c264d2 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _74c1866d = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _0daba6da = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _6066ef78 = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _2a951f79 = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _6bb7a242 = () => interopDefault(import('../uicore/pages/arm/admin/system/registryLayers.vue' /* webpackChunkName: "pages/arm/admin/system/registryLayers" */))
const _555acbc4 = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _1b0c1a7c = () => interopDefault(import('../uicore/pages/arm/admin/system/layers.vue' /* webpackChunkName: "pages/arm/admin/system/layers" */))
const _3074c28d = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _2c7b60be = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _614b2151 = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _4298ae2a = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _d2ba32ee = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _303b0216 = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _6068b2ae = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _5b1a49da = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _e7d7e55a = () => interopDefault(import('../uicore/pages/base/auth/login.vue' /* webpackChunkName: "pages/base/auth/login" */))
const _e6cc344c = () => interopDefault(import('../uicore/pages/base/auth/forgotPass.vue' /* webpackChunkName: "pages/base/auth/forgotPass" */))
const _654e92ce = () => interopDefault(import('../uicore/pages/base/application/list.vue' /* webpackChunkName: "pages/base/application/list" */))
const _7956804d = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _6a114a18 = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _1e79cf7a = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _5d30ba3d = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _15f42ba8 = () => interopDefault(import('../uicore/pages/base/registries.vue' /* webpackChunkName: "pages/base/registries" */))
const _27445c02 = () => interopDefault(import('../uicore/pages/base/geoportal/index.vue' /* webpackChunkName: "pages/base/geoportal/index" */))
const _d8069d56 = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _72f1a2eb = () => interopDefault(import('../uicore/pages/arm/registries.vue' /* webpackChunkName: "pages/arm/registries" */))
const _3fe2caee = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _ad359b6e = () => interopDefault(import('../uicore/pages/arm/login.vue' /* webpackChunkName: "pages/arm/login" */))
const _aa1a8ec0 = () => interopDefault(import('../uicore/pages/arm/geoportal/index.vue' /* webpackChunkName: "pages/arm/geoportal/index" */))
const _2c276aa4 = () => interopDefault(import('../uicore/pages/arm/forgotPass.vue' /* webpackChunkName: "pages/arm/forgotPass" */))
const _7da088af = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))
const _38f80351 = () => interopDefault(import('../uicore/pages/base/index.vue' /* webpackChunkName: "pages/base/index" */))
const _4084e19c = () => interopDefault(import('../uicore/pages/arm/index.vue' /* webpackChunkName: "pages/arm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _5057aeb6,
    name: "index"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _a1ccc972,
    name: "base-check_doc-fileId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _786c6439,
    name: "arm-resetPass-token"
  }, {
    path: "/base/service/list/:parentId?",
    component: _b6723d84,
    name: "base-service-list-parentId"
  }, {
    path: "/base/service/info/:tsrId?",
    component: _89d1f496,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _02671eef,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/application/process/:asId?",
    component: _0ec6972c,
    name: "base-application-process-asId"
  }, {
    path: "/base/application/monitor/:appId?",
    component: _2b3cb2bc,
    name: "base-application-monitor-appId"
  }, {
    path: "/arm/service/list/:parentId?",
    component: _8abf1906,
    name: "arm-service-list-parentId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _7cb49854,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _7e6f2ee6,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _789a0161,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _17c264d2,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _74c1866d,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/base/application/result/appId",
    component: _0daba6da,
    name: "base-application-result-appId"
  }, {
    path: "/arm/application/view/asId",
    component: _6066ef78,
    name: "arm-application-view-asId"
  }, {
    path: "/arm/admin/system/route",
    component: _2a951f79,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/admin/system/registryLayers",
    component: _6bb7a242,
    name: "arm-admin-system-registryLayers"
  }, {
    path: "/arm/admin/system/list",
    component: _555acbc4,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/layers",
    component: _1b0c1a7c,
    name: "arm-admin-system-layers"
  }, {
    path: "/arm/admin/system/control",
    component: _3074c28d,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/calendar",
    component: _2c7b60be,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/app",
    component: _614b2151,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/org/service",
    component: _4298ae2a,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/org/employee",
    component: _d2ba32ee,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/app",
    component: _303b0216,
    name: "arm-admin-org-app"
  }, {
    path: "/base/auth/register",
    component: _6068b2ae,
    name: "base-auth-register"
  }, {
    path: "/base/auth/profile",
    component: _5b1a49da,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/login",
    component: _e7d7e55a,
    name: "base-auth-login"
  }, {
    path: "/base/auth/forgotPass",
    component: _e6cc344c,
    name: "base-auth-forgotPass"
  }, {
    path: "/base/application/list",
    component: _654e92ce,
    name: "base-application-list"
  }, {
    path: "/arm/application/list",
    component: _7956804d,
    name: "arm-application-list"
  }, {
    path: "/arm/application/income",
    component: _6a114a18,
    name: "arm-application-income"
  }, {
    path: "/oldAppsList/employee",
    component: _1e79cf7a,
    name: "oldAppsList-employee"
  }, {
    path: "/oldAppsList/applicant",
    component: _5d30ba3d,
    name: "oldAppsList-applicant"
  }, {
    path: "/base/registries",
    component: _15f42ba8,
    name: "base-registries"
  }, {
    path: "/base/geoportal",
    component: _27445c02,
    name: "base-geoportal"
  }, {
    path: "/base/faq",
    component: _d8069d56,
    name: "base-faq"
  }, {
    path: "/arm/registries",
    component: _72f1a2eb,
    name: "arm-registries"
  }, {
    path: "/arm/profile",
    component: _3fe2caee,
    name: "arm-profile"
  }, {
    path: "/arm/login",
    component: _ad359b6e,
    name: "arm-login"
  }, {
    path: "/arm/geoportal",
    component: _aa1a8ec0,
    name: "arm-geoportal"
  }, {
    path: "/arm/forgotPass",
    component: _2c276aa4,
    name: "arm-forgotPass"
  }, {
    path: "/Test",
    component: _7da088af,
    name: "Test"
  }, {
    path: "/base",
    component: _38f80351,
    name: "base"
  }, {
    path: "/arm",
    component: _4084e19c,
    name: "arm"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
