

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: true,
  locales: [{"name":"ru","code":"ru","file":"ru.js"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "../lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"ru","code":"ru","file":"ru.js"}],
  localeCodes: ["ru"],
  additionalMessages: [{"ru":{"ggo_module@geoportalUploadObject":{"label":"Загрузите файл в формате geojson","uploadBtn":"Выберите файл","removeObjectBtn":"Удалить объект"}}}],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "r",
  3: "u",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "r",
  19: "u",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: "}",
}

export const localeMessages = {
  'ru.js': () => import('../../lang/ru.js' /* webpackChunkName: "lang-ru.js" */),
}
